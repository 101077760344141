import algoliasearch from 'algoliasearch';
import { fetchAlgoliaKeys } from '../api/algolia-api-keys';

let client = null;
let keys = null;
let lastFetched = 0;
let refreshing = false;

const refreshKeys = async () => {
  if (refreshing) return;
  refreshing = true;

  try {
    const fetchedKeys = await fetchAlgoliaKeys();
    if (fetchedKeys) {
      keys = fetchedKeys;
      client = algoliasearch(keys.appId, keys.searchApiKey);
      lastFetched = Date.now();
    }
  } catch (error) {
    console.error('Error refreshing Algolia keys:', error);
  }

  refreshing = false;
};

export const getAlgoliaClient = async () => {
  const now = Date.now();

  if (!keys || now - lastFetched > 30 * 60 * 1000) {
    refreshKeys();
  }

  return client;
};

export const getAlgoliaKeys = async () => {
  if (!keys) await refreshKeys();
  return keys;
};

// Initialize keys once on app startup
refreshKeys();
