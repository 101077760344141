export default {
  COMPANY: {
    NAME_INPUT: 'name-input',
    TYPE_DROPDOWN: 'type-dropdown',
    TYPE_DROPDOWN_OPTION: 'type-dropdown-option',
    CREATE_SAVE_BUTTON: 'create-save-button',
    UTA_RELATIONSHIP_CARD: 'uta-relationship-card',
    BASIC_INFO_CARD: 'basic-info-card',
    COMPANY_NAME_TITLE: 'company-name-title',
    TOURING_AGENT_CARD: 'touring-agent-card',
  },
  PERSON: {
    NAME_INPUT: 'name-input',
    TYPE_INPUT: 'type-input',
    TYPE_DROPDOWN: 'type-dropdown',
    TYPE_DROPDOWN_OPTION: 'type-dropdown-option',
    CREATE_SAVE_BUTTON: 'create-save-button',
    UTA_RELATIONSHIP_CARD: 'uta-relationship-card',
    BASIC_INFO_CARD: 'basic-info-card',
    NAME_TITLE: 'name-title',
    TOURING_AGENT_CARD: 'touring-agent-card',
  },
  COMMON: {
    ROW_DELETE_CROSS_BUTTON: 'row-delete-cross-button',
    SIDENAV_TAB: 'sidenav_tab',
    CREATE_SAVE_BUTTON: 'create-save-button',
  },
  SEARCH: {
    DROPBOX_CHECK_CLIENT: 'dropbox-check-client',
    DROPBOX_CHECK_INDUSTRY_CONTACT: 'dropbox-check-industry-contact',
    DROPBOX_CHECK_SHARED_CONTACT: 'dropbox-check-shared-contact',
    DROPBOX_CHECK_EMPLOYEE: 'dropbox-check-employee',
    DROPBOX_CHECK_OUTLOOK: 'dropbox-check-outlook',
    DROPBOX_CHECK_COMPANY: 'dropbox-check-company',
    DROPBOX_CHECK_PERSON: 'dropbox-check-person',
    MAIN_SEARCH_INPUT: 'main-search-input',
  },
  PROJECT_FORM: {
    PROJECT_FORM: 'project-form',
    PROJECT_NAME: 'project-form-name',
    PROJECT_TYPE: 'project-form-type',
    ADD_PROJECT_BUTTON: 'project-form-add-project',
    CANCEL_PROJECT_FORM: 'project-form-cancel',
    CHECKMARK_BUTTON: 'project-form-checkmark-button',
    CANCEL_BUTTON: 'project-form-cancel-button',
  },
  SEARCH_PROJECTS: {
    SEARCH_PROJECTS_INPUT: 'search-projects-input',
    PROJECT_RESULT_COUNT: 'project-result-count',
    LOADED_PROJECTS: 'loaded-projects',
    RESET_BUTTON: 'reset-button',
  },
  PROJECT_FILTERS: {
    FILTERS_BARDGES: 'filters-badges',
    PROJECT_STATUS_DROPDOWN: 'project-status-dropdown',
    PROJECT_TYPES_DROPDOWN: 'project-types-dropdown',
    PROJECT_GENRES_DROPDOWN: 'project-genres-dropdown',
  },
  PROJECT: {
    PROJECT_NAME_EDIT_INPUT: 'project-name-edit-input',
    PROJECT_NAME_EDIT_SAVE_BUTTON: 'project-name-edit-save-button',
    PROJECT_NAME_EDIT_BUTTON: 'project-name-edit-button',
    PROJECT_DUPLICATE_BUTTON: 'project-duplicate-button',
    PROJECT_DUPLICATE_NAME_INPUT: 'project-duplicate-name-input',
    PROJECT_DUPLICATE_CONFIRM_BUTTON: 'project-duplicate-confirm-button',
    PROJECT_TITLE: 'project-title',
    STUDIO_INPUT: 'studio-input',
    ADD_AUSPICE_BUTTON: 'add-auspice-button',
    AUSPICE_PERSON_INPUT: 'auspice-person-input',
    AUSPICE_STATUS_INPUT: 'auspice-status-input',
    AUSPICE_AGENCY_INPUT: 'auspice-agency-input',
    AUSPICE_POSITION_INPUT: 'auspice-position-input',
    AUSPICE_SAVE_BUTTON: 'auspice-save-button',
    AUSPICES: 'auspices',
    ADD_CASTING_BUTTON: 'add-casting-button',
    CASTING_ROLE_INPUT: 'casting-role-input',
    CASTING_GENEDER_INPUT: 'casting-gender-input',
    CASTING_TYPE_INPUT: 'casting-type-input',
    CASTING_TALENT_INPUT: 'casting-talent-input',
    CASTING_STATUS_INPUT: 'casting-status-input',
    SAVE_CASTING_BUTTON: 'save-casting-button',
    CASTINGS: 'castings',
    LOCATIONS: 'location',
    EDIT_BUTTON: 'edit-button',
  },
  PROJECT_ACTIONS: {
    ADD_TO_LIST_BUTTON: 'add-to-list',
    LIST_NAME_INPUT: 'list-name-input',
    CREATE_LIST_BUTTON: 'create-list-button',
    ADD_TO_CURRENT_LIST_BUTTON: 'add-to-current-list-button',
    EXISTING_LISTS: 'existing-lists',
    SAVE_AS_GRID_BUTTON: 'save-as-grid-button',
    GRID_NAME_INPUT: 'grid-name-input',
    SAVE_NEW_GRID_BUTTON: 'save-new-grid-button',
    GRID_NAME: 'grid-name',
    ADD_TO_EXISTING_LIST_BUTTON: 'add-to-existing-list-button',
    LIST_NAME: 'list-name',
    TOGGLE_PRINT_BUTTON: 'toggle-print-button',
    PRINT_PROJECTS: 'print-projects',
    SORT_BY_PRINT: 'sort-by-print',
    PRINT_TOC: 'print-toc',
  },

  PROJECT_CASTINGS: {
    PROJECT_CASTINGS_BUTTON: 'project-castings-button',
    PROJECT_CASTINGS_INPUT: 'project-castings-input',
    PROJECT_CASTINGS_RESULT_COUNT: 'project-castings-result-count',
    LOADED_PROJECTS_CASTINGS: 'loaded-projects',
    RESET_BUTTON: 'reset-button',
  },
};
