import { loadPerson, createStoreWithOptions } from '@united-talent-agency/julius-frontend-store';
import { apiServerUrl } from '../support/urls';
import { getPeopleIndex } from '../support/algolia/get-index';
import { searchClient } from '../support/algolia/algolia-search-client';

const store = createStoreWithOptions({ apiServerUrl });
const { dispatch } = store;

export const getPrimaryAgency = agencies => {
  if (!agencies || !agencies.length || !agencies.length > 0) {
    return null;
  }
  const primaryAgency = agencies.find(rba => {
    return rba.primary === true;
  });

  const softPrimary = !primaryAgency && (agencies[0].company.soft = true) && agencies[0];
  const retVal = primaryAgency || softPrimary;
  return retVal.company;
};
export const getAgencyCompanies = agencies => {
  if (!agencies) {
    return [];
  }
  const retVal = [];
  agencies.forEach(rba => {
    if (rba.primary) {
      //front of the bus
      retVal.unshift(rba.company);
    } else {
      //back of the bus
      retVal.push(rba.company);
    }
  });
  return retVal;
};
export const getAgenciesByPersonId = (
  personId,
  _dispatch = dispatch,
  _loadPerson = loadPerson,
  _getPrimaryAgency = getPrimaryAgency,
  _getAgencyCompanies = getAgencyCompanies
) => {
  return _dispatch(_loadPerson(personId)).then(p1 => {
    const personDetails = p1.body;
    const { representedByAgencies = [] } = personDetails;
    const primary = _getPrimaryAgency(representedByAgencies);
    const agencies = _getAgencyCompanies(representedByAgencies);
    return { primary, agencies };
  });
};
export const getAgenciesByPersonName = async (
  name,
  _dispatch = dispatch,
  _searchPeople = undefined,
  _loadPerson = loadPerson,
  _getAgenciesByPersonId = getAgenciesByPersonId
) => {
  const index = await getPeopleIndex();
  const indexSetting = {
    filters: "(type: 'Client') OR (type: 'Industry Contact') OR (type: 'Employee')",
    hitsPerPage: 10,
    page: 0,
    restrictSearchableAttributes: ['name'],
  };
  const searchPeople = _searchPeople ? _searchPeople(name) : searchClient(index, name, indexSetting);
  return searchPeople
    .then(({ hits: results }) => {
      const getPeoplePromises = [];
      //may be many "person" records with the same name... and different representedByAgencies... fun
      results.forEach(possible => {
        getPeoplePromises.push(_getAgenciesByPersonId(possible._id, _dispatch, _loadPerson));
      });
      return Promise.all(getPeoplePromises);
    })
    .then(peopleResults => {
      const retVal = peopleResults.reduce(
        (obj, result) => {
          if (result.primary && (!result.primary.soft || !obj.primary)) {
            obj.primary = result.primary;
            //remove it from agency list
            obj.agencies = obj.agencies.filter(agency => {
              return agency._id.toString() !== result.primary._id.toString();
            });
            //add it back at the top
            obj.agencies.unshift(result.primary);
          }
          if (result.agencies) {
            result.agencies.forEach(oResult => {
              if (
                !obj.agencies.some(o => {
                  return o._id.toString() === oResult._id.toString();
                })
              ) {
                obj.agencies.push(oResult);
              }
            });
          }
          return obj;
        },
        { agencies: [] }
      );
      return retVal;
    });
};

export const getRepresentedByAgencies = async (
  personSearchResult,
  _dispatch = dispatch,
  _getAgenciesByPersonId = getAgenciesByPersonId,
  _getAgenciesByPersonName = getAgenciesByPersonName
) => {
  if (personSearchResult._id.match(/^[0-9a-fA-F]{24}$/)) {
    return _getAgenciesByPersonId(personSearchResult._id, _dispatch);
  } else {
    return await _getAgenciesByPersonName(personSearchResult._id, _dispatch);
  }
};

export default {
  getRepresentedByAgencies,
  getAgenciesByPersonId,
  getAgenciesByPersonName,
  getAgencyCompanies,
  getPrimaryAgency,
};
