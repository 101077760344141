import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { icons, sizes } from '@united-talent-agency/julius-frontend-components';
import { Spinner } from '@united-talent-agency/components';
import Tooltip from '@material-ui/core/Tooltip';

class DoneButtons extends React.Component {
  render() {
    const { classes, onDone, isActive, isDeclineOnly, isSaving, disabled, showToolTip, toolTipTitle } = this.props;

    

    const saveButton = !isSaving ? (
      <Tooltip title={showToolTip ? toolTipTitle : ''}>
        <button disabled={disabled || !isActive || isSaving || showToolTip} id="done-button" type="submit" className={classes.button}>
          <i className={classes.confirmIcon} />
        </button>
      </Tooltip>
    ) : (
      <Spinner size={18} />
    );

    return (
      <div className={classnames(classes.section, classes.done)}>
        <button disabled={disabled} type="button" id="decline-button" className={classes.button} onClick={onDone}>
          <i className={classes.declineIcon} />
        </button>
        {!isDeclineOnly && saveButton}
      </div>
    );
  }
}

const styles = {
  button: {
    background: 'none',
    border: 0,
    outline: 0,
    margin: 0,
    cursor: 'pointer',
    padding: 10,
    lineHeight: 0,
  },
  confirmIcon: icons.check,
  declineIcon: icons.cross,
  section: {
    margin: 6,
    [`@media ${sizes.mobileBreakpoint}`]: {
      margin: '0 15px 15px',
    },
  },
  done: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
  },
};

// eslint-disable-next-line no-class-assign
DoneButtons = withStyles(styles)(DoneButtons);

export { DoneButtons };
