import React, { Fragment } from 'react';
import { styled } from 'react-free-style';
import { Col, Row } from 'reactstrap';

import { Card } from '@united-talent-agency/julius-frontend-components';

import { VerificationIcon } from '@united-talent-agency/components';

import { getPeopleIndex } from '../../support/algolia/get-index';
import { searchClient } from '../../support/algolia/algolia-search-client';

export const CLASSNAMES = {
  cardHeader: 'casting-header',
  cardEntry: 'casting-entry',
};

const InfoListCard = ({ styles, title, cols = [], items = [], searchAlgolia = false, dataCy }) => {
  const [verificationObj, setVerificationObj] = React.useState({});
  const [itemsToRender, setItemsToRender] = React.useState([]);

  React.useEffect(() => {
    if (!searchAlgolia) {
      setItemsToRender(items);
      return;
    }
    const verificationPromises = [];
    for (const item of items) {
      if (item?.person?.personId || item?.talent?.personId) {
        const personId = item?.person?.personId || item?.talent?.personId;
        getPeopleIndex().then(index => {
          const indexSetting = {
            filters: `(objectID: ${personId})`,
            hitsPerPage: 1,
            page: 0,
          };

          verificationPromises.push(
            searchClient(index, '', indexSetting).then(({ hits: results }) => {
              const verifiedOn = results[0]?.verifiedOn;
              const verifiedBy = results[0]?.verifiedBy?.first_name + ' ' + results[0]?.verifiedBy?.last_name;
              setVerificationObj(prevVerificationObj => {
                prevVerificationObj[personId] = {
                  verifiedOn,
                  verifiedBy,
                  verified: verifiedOn && verifiedBy,
                };
                return prevVerificationObj;
              });
            })
          );
        });
      }
    }
    Promise.all(verificationPromises).then(() => setItemsToRender(items));
  }, [items, searchAlgolia]);

  return (
    <Card title={title}>
      {() => {
        return (
          <div data-cy={dataCy}>
            <Row className={styles.header}>
              {cols.map((col, index) => (
                <Col key={index} style={{ textTransform: 'uppercase' }}>
                  {col}
                </Col>
              ))}
            </Row>
            {itemsToRender.map((item, index) => (
              <Fragment key={index}>
                <hr style={{ margin: 0 }} />
                <Row className={`${styles.rows} ${CLASSNAMES.cardEntry}`} key={index}>
                  {cols.map((col, index) => {
                    const colToRender = (
                      <Col key={index}>
                        <span className="mr-1" style={item[col].style}>
                          {item[col]?.text ? item[col].text : typeof item[col] === 'string' ? item[col] : 'N/A'}
                        </span>
                        {verificationObj[item[col]?.personId]?.verified ? (
                          <VerificationIcon
                            color="verifiedPersonIcon"
                            verifiedBy={verificationObj[item[col]?.personId]?.verifiedBy}
                            verifiedOn={verificationObj[item[col]?.personId]?.verifiedOn}
                            identifier={item[col]?.personId}
                            includeExplanation
                          />
                        ) : null}
                      </Col>
                    );
                    return colToRender;
                  })}
                </Row>
              </Fragment>
            ))}
          </div>
        );
      }}
    </Card>
  );
};

const withStyles = styled({
  header: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 2,
    marginBottom: 2,
    color: 'gray',
  },
  rows: {
    fontWeight: 300,
    fontSize: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  castingDetails: {
    fontWeight: 300,
  },
  boldedCastingName: {
    fontWeight: 800,
  },
});

export default withStyles(InfoListCard);
