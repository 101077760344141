import React from 'react';
import classnames from 'classnames';

import { DoneButtons, PersonInput, elements, icons } from '@united-talent-agency/julius-frontend-components';
import { helpers, styled } from 'react-free-style';

import { personProfileLink } from '../../../support/person';
import { getPeopleIndex } from '../../../support/algolia/get-index';
import { searchClient } from '../../../support/algolia/algolia-search-client';

class PeopleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      person: null,
      name: '',
    };
  }

  async onNameChange(name) {
    const index = await getPeopleIndex();
    const indexSetting = {
      filters: "(type: 'Client') OR (type: 'Industry Contact') OR (type: 'Employee')",
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    const { hits: results } = await searchClient(index, name, indexSetting);
    this.setState({ results });
  }

  getDisplayType(type) {
    switch (type) {
      case 'Employee':
        return 'Team';
      case 'Client':
        return 'Clients';
      default:
        return 'Contacts';
    }
  }

  // eslint-disable-next-line no-undef
  onSubmit = e => {
    e.preventDefault();
    const { person } = this.state;
    const { onPersonAdded } = this.props;
    onPersonAdded && onPersonAdded(person);
    this.setState({ person: null, name: '' });
  };

  // eslint-disable-next-line no-undef
  onDone = () => {
    this.setState({ isSaving: false, name: '', person: {}, results: [] });
  };

  // eslint-disable-next-line no-undef
  removePerson = person => {
    const { onPersonRemoved } = this.props;
    onPersonRemoved && onPersonRemoved(person);
  };

  render() {
    const { styles, tracking } = this.props;
    const isSteward = false;
    const people = {};
    const trackingPeople = tracking?.people || [];
    trackingPeople.forEach(person => {
      const mappedType = this.getDisplayType(person?.type);
      people[mappedType] ? people[mappedType].push(person) : (people[mappedType] = [person]);
    });

    return (
      <div className={styles.gridList}>
        <form onSubmit={e => this.onSubmit(e)}>
          <div className={classnames(styles.section, styles.nameInput)}>
            <div className={styles.personInput}>
              <PersonInput
                notRequired={true}
                results={this.state.results}
                value={this.state.name}
                focus
                onChange={name => {
                  this.setState({ name });
                  this.onNameChange(name);
                }}
                onSelect={person => {
                  this.setState({ person, name: person.name });
                }}
              />
            </div>
            <DoneButtons isActive={true} isSaving={this.isSaving} onDone={() => this.onDone()} />
          </div>
        </form>
        <div className={styles.gridList}>
          {Object.entries(people).map((personObj, index) => (
            <div key={index} className={styles.section}>
              <h2>{personObj[0]}</h2>
              {personObj[1].map((person, idx) => (
                <li key={idx}>
                  <span className={styles.clear} onClick={() => this.removePerson(person)}>
                    <i className={styles.clearIcon} />
                  </span>
                  <span>
                    <a
                      className={styles.profileLink}
                      href={personProfileLink(person?._id, isSteward)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {person?.name}
                    </a>
                  </span>
                </li>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const withStyles = styled({
  nameInput: {
    display: 'flex',
    flexDirection: 'row',
  },
  personInput: {
    width: '60%',
  },
  gridList: {
    listStyle: 'none',
    width: '80%',
    marginLeft: '30px',
    marginRight: '15px',
    paddingTop: '10px',
    justifyContent: 'left',
  },
  clear: helpers.merge(elements.actionable, {
    top: 0,
    right: 0,
    padding: 13,
    lineHeight: 0,
  }),
  clearIcon: helpers.merge(icons.cross, {
    width: icons.cross.width * 0.8,
    height: icons.cross.height * 0.8,
  }),
  section: {
    marginBottom: 20,
  },
  profileLink: {
    cursor: 'pointer',
    color: '#000',
  },
});

export default withStyles(PeopleView);
