import React, { useState, useEffect, useMemo } from 'react';
import Select, { components } from 'react-select';
import debounce from 'lodash.debounce';
import Tooltip from '@material-ui/core/Tooltip';
import { City, State, Country } from '../../support/country-state-city';
import cypressTags from '../../support/cypressTags';

const MultiSelectCities = ({ defaultValues = [], onSelectionChange }) => {
  const [locations, setLocations] = useState([]); // All locations loaded
  const [filteredLocations, setFilteredLocations] = useState([]); // Filtered locations based on search term
  const [selectedOptions, setSelectedOptions] = useState([]); // Selected options
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [loading, setLoading] = useState(false); // Loading state for infinite scroll
  const [searchTerm, setSearchTerm] = useState(''); // Track search term
  const [isFocused, setIsFocused] = useState(false);
  const pageSize = 50; // Number of items per page

  // Load all locations once when the component mounts
  useEffect(() => {
    const loadLocations = () => {
      const uniqueLocations = new Map();

      City.getAllCities().forEach(city => {
        const state = State.getStateByCodeAndCountry(city.stateCode, city.countryCode);
        const country = Country.getCountryByCode(city.countryCode);

        if (!state || !country) return; // Skip invalid entries

        const uniqueKey = `${city.name}-${state.name}-${country.name}`;
        const label = `${city.name}, ${state.name}, ${country.name}`;
        const value = uniqueKey;

        if (!uniqueLocations.has(uniqueKey)) {
          uniqueLocations.set(uniqueKey, {
            label,
            value,
            city: city.name,
            state: state.name,
            country: country.name,
          });
        }
      });

      // Convert the map to an array and sort cities alphabetically
      const sortedLocations = Array.from(uniqueLocations.values()).sort(
        (a, b) => a.city.localeCompare(b.city) // Sort by city name alphabetically
      );

      setLocations(sortedLocations); // Set all locations
    };

    loadLocations();
  }, []);

  // Handle search input with debounce
  const handleSearch = useMemo(
    () =>
      debounce(term => {
        setSearchTerm(term); // Update search term
        setLoading(true); // Start loading

        if (!term) {
          setFilteredLocations([]); // If no search term, show no results
          setCurrentPage(1); // Reset page number
          setLoading(false); // No need to load, reset loading state
        } else {
          const termLower = term.toLowerCase();
          // Filter locations that match city name exactly first
          const exactMatches = locations.filter(loc => loc.city.toLowerCase() === termLower);
          // Filter locations that match partially (city, state, country)
          const partialMatches = locations.filter(
            loc =>
              loc.city.toLowerCase().includes(termLower) ||
              loc.state.toLowerCase().includes(termLower) ||
              loc.country.toLowerCase().includes(termLower)
          );
          // Combine exact matches and partial matches, removing duplicates
          const filteredResults = [...new Set([...exactMatches, ...partialMatches])];
          setFilteredLocations(filteredResults);
          setCurrentPage(1); // Reset to first page on search change
          setLoading(false); // Finished filtering, reset loading state
        }
      }, 300),
    [locations]
  );

  const handleInputChange = inputValue => {
    handleSearch(inputValue);
  };

  const handleChange = selected => {
    // Ensure selected is never null, treat null as an empty array
    const selectedArray = selected || [];

    // Handle the case where no items are selected
    if (selectedArray.length === 0) {
      setSelectedOptions([]); // Clear selected options
      onSelectionChange([]); // Notify parent with an empty array
      return;
    }

    // Sort selected options by label
    const sortedSelected = [...selectedArray].sort((a, b) => a.label.localeCompare(b.label));

    // Update selected options and notify parent
    setSelectedOptions(sortedSelected);
    onSelectionChange(sortedSelected.map(({ value }) => value)); // Pass sorted city names
  };

  // Infinite Scroll: Load more data when the user scrolls to the bottom
  const handleMenuScrollToBottom = () => {
    if (!loading && (searchTerm ? filteredLocations.length : locations.length) > currentPage * pageSize) {
      setLoading(true); // Set loading state to true while fetching data

      // Increment page and update filteredLocations for infinite scroll
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  // Paginate filtered locations based on current page
  const paginatedLocations = useMemo(() => {
    const listToPaginate = searchTerm ? filteredLocations : locations; // Use filtered locations or full locations based on search term
    const startIndex = (currentPage - 1) * pageSize;
    return listToPaginate.slice(0, startIndex + pageSize); // Return slice of locations based on current page
  }, [filteredLocations, currentPage, locations, searchTerm]);

  // Reset loading state after data is updated
  useEffect(() => {
    if (filteredLocations.length > 0 || !searchTerm) {
      setLoading(false); // Reset loading once data is loaded
    }
  }, [filteredLocations, currentPage, searchTerm]);

  useEffect(() => {
    return () => handleSearch?.cancel();
  }, [handleSearch]);

  useEffect(() => {
    if (locations.length === 0) return; // Skip if locations are not yet loaded
    const matchedOptions = locations.filter(location => defaultValues.includes(location.value)).sort();
    if (matchedOptions.length !== selectedOptions.length) {
      setSelectedOptions(matchedOptions);
    }
  }, [defaultValues, locations]);

  return (
    <span data-cy={cypressTags.PROJECT.LOCATIONS}>
      <Select
        inputId="cities-dropdown-input"
        aria-label="Select location(s)"
        isMulti
        options={paginatedLocations} // Always show paginated locations based on search term or full list
        value={selectedOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        components={CustomComponents}
        placeholder="Location(s)"
        getOptionLabel={e => e.label}
        getOptionValue={e => e.value}
        onMenuScrollToBottom={handleMenuScrollToBottom} // Infinite scroll trigger
        isLoading={loading} // Show loading spinner when data is loading
        styles={customStyles}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        menuIsOpen={isFocused || !!searchTerm}
        noOptionsMessage={() =>
          searchTerm ? (filteredLocations.length === 0 ? 'No matching location(s) found' : null) : 'Type to search...'
        }
      />
    </span>
  );
};

export default MultiSelectCities;

const CustomComponents = {
  ClearIndicator: props => <ClearIndicator {...props} />,
};

export const ClearIndicator = props => {
  return (
    <Tooltip title="Clear all">
      <div id="clearAll">
        <components.ClearIndicator {...props} />
      </div>
    </Tooltip>
  );
};

const customStyles = {
  menu: provided => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    zIndex: 999,
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      backgroundColor: isDisabled ? null : isSelected ? 'grey' : isFocused ? 'grey' : null,
      color: isDisabled ? 'lightgrey' : isSelected ? 'white' : isFocused ? 'white' : 'black',
      cursor: 'default',
      '&:active': {
        backgroundColor: 'black',
        color: 'white',
      },
    };
  },
  control: (provided, state) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: state.isFocused ? '#808080' : '#c4c4c4',
    borderWidth: state.isFocused ? '2px' : '1px',
    '&:hover': {
      borderColor: state.isFocused ? '#c4c4c4' : 'black',
    },
  }),
  multiValue: provided => ({ ...provided, margin: '5px' }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: state.selectProps.menuIsOpen || state.selectProps.inputValue ? '10px' : '1rem',
    transform: state.selectProps.menuIsOpen || state.selectProps.inputValue ? 'translateY(-10px)' : 'translateY(0)',
    transition: 'font-size 0.2s, transform 0.2s',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.25px',
    paddingLeft: '5px',
    position: 'absolute',
    color: '#808080',
    padding: state.selectProps.menuIsOpen || state.selectProps.inputValue ? '2px 0 0 5px' : 0,
    top: '25%',
  }),
  singleValue: provided => ({
    ...provided,
    backgroundColor: 'grey',
  }),
  indicatorSeparator: () => {},
};
