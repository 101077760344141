import React, { useState } from 'react';
import classnames from 'classnames';
import { styled } from 'react-free-style';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import { PersonInput } from '@united-talent-agency/julius-frontend-components';

import { getPeopleIndex } from '../../../../../../support/algolia/get-index';
import { searchClient } from '../../../../../../support/algolia/algolia-search-client';

import { DoneButtons } from '../../../../../../components/done-buttons/done-buttons';

const TYPES = ['Finance Plan', 'Need', 'Note'];

const defaultOnNameChange = async name => {
  if(name !== ''){
    const index = await getPeopleIndex();
    const indexSetting = {
      filters: "(type: 'Employee')",
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    const { hits: results } = await searchClient(index, name, indexSetting);
    return results;
  } else {
    return [];
  }
};

const AddNoteForm = ({ styles, onSubmit, desk, user, onReset, projectId, defaultNoteFields = {}, onNameChange = defaultOnNameChange }) => {
  const { agentId } = desk;

  const defaultUserInfo = { _id: user.personId, name: `${user.first_name} ${user.last_name}` };
  // first pull from optional update state, then the agentId on the desk, then the logged in user
  const defaultAuthor = defaultNoteFields.author || agentId || defaultUserInfo;

  const [potentialAuthors, setPotentialAuthors] = useState([]);
  const [author, setAuthor] = useState(defaultAuthor);
  const [note, setNote] = useState(defaultNoteFields.note || '');
  const [status, setStatus] = useState(defaultNoteFields.status || '');

  const [isSaving, setIsSaving] = useState(false);

  const [statusError, setStatusError] = useState(false);
  const [noteError, setNoteError] = useState(false);
  const [authorError, setAuthorError] = useState(false);

  const reset = () => {
    setAuthor(agentId || {});
    setNote('');
    setStatus('');
    setPotentialAuthors([]);
    onReset();
  };

  const submit = event => {
    event.preventDefault();
    let errors = false;
    if (status === '') {
      errors = true;
      setStatusError(true);
    }
    if (note === '') {
      errors = true;
      setNoteError(true);
    }
    if (isEmpty(author)) {
      errors = true;
      setAuthorError(true);
    }
    if (!errors) {
      const indieNoteBody = {
        note,
        status,
        projectId,
        personId: author._id,
        type: 'Indie',
        internal: false,
      };
      setIsSaving(true);
      onSubmit(indieNoteBody, defaultNoteFields.noteId);
    }
  };

  return (
    <form name='addNoteForm' className={styles.addNoteForm} onSubmit={submit}>
      <div className={styles.inputRow}>
        <div className={classnames(styles.section, styles.personInput)}>
          <PersonInput
            error={authorError}
            helperText={authorError && 'Required'}
            inline
            results={potentialAuthors}
            value={author.name}
            label="Agent"
            focus
            onChange={name => onNameChange(name).then(results => setPotentialAuthors(results)).catch(console.error)}
            onSelect={person => {
              authorError && setAuthorError(false);
              setAuthor(person);
            }}
          />
        </div>
        <div className={classnames(styles.section, styles.nameInput)}>
          <TextField
            inputProps={{ "data-testid": "type-input" }}
            error={statusError}
            helperText={statusError && 'Required'}
            select
            variant="outlined"
            fullWidth
            label="Type"
            value={status}
            onChange={({ target }) => {
              statusError && setStatusError(false);
              setStatus(target.value);
            }}
          >
            {TYPES.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={classnames(styles.section, styles.textarea)}>
          <TextField
            inputProps={{ "data-testid": "description-input" }}
            error={noteError}
            helperText={noteError && 'Required'}
            multiline
            cols={10}
            fullWidth
            name="Note"
            label="Note"
            variant="outlined"
            value={note}
            onChange={({ target }) => {
              noteError && setNoteError(false);
              setNote(target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.doneButtonsContainer}>
        <DoneButtons isActive onDone={reset} isSaving={isSaving} />
      </div>
    </form>
  );
};

const withStyles = styled({
  addNoteForm: {
    padding: '25px 10px 0 10px',
    maxWidth: '1000px',
    border: '1px black solid',
    backgroundColor: 'white',
  },
  container: {
    marginBottom: 20,
    display: 'flex',
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    margin: 10,
  },
  doneButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  nameInput: {
    flex: 0.8,
  },
  personInput: {
    flex: 0.5,
  },
  textarea: {
    flex: 1,
  },
});

export default withStyles(AddNoteForm);
