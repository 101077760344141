import React, { Component } from 'react';
import { styled } from 'react-free-style';
import Modal from 'react-modal';
import classnames from 'classnames';

import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import { DoneButtons } from '@united-talent-agency/julius-frontend-components';

import { toUnixTime } from '../../../support/date';
import { projectTypes } from '../../../support/items/filter-types';
import { colors } from '../../../styles/colors';
import Tooltip from '@material-ui/core/Tooltip';
import cypressTags from '../../../support/cypressTags';

const { warningRed } = colors;

export const CLASSES = {
  projectTypeSelector: 'project-type',
};

Modal.setAppElement(document.getElementById('duplicate-project-modal'));

class ProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      missingNameInput: false,
      missingTypeInput: false,
      type: '',
      name: '',
      showDuplicateModal: false,
      newProject: {},
      duplicateProjects: [],
    };
  }

  async submit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    const { name, type } = this.state;
    const nameSanitized = name.trim();
    const project = {
      active: true,
      name: nameSanitized,
      type,
      created_date: toUnixTime(new Date()),
    };

    if (nameSanitized === '') {
      this.setState({ isSaving: false, missingNameInput: true });
    }
    if (!type) {
      this.setState({ isSaving: false, missingTypeInput: true });
    }
    if (nameSanitized !== '' && type) {
      this.setState({ isSaving: true });
    } else {
      return;
    }

    // lookup dupes, if we find one- show the modal
    const result = await this.props.searchDupes({ name: nameSanitized, type: type });
    const potentialDuplicateProjects = result && result?.body && result?.body?.data ? result?.body?.data : [];
    const duplicateProjects = potentialDuplicateProjects.filter(
      each => each.name && each.name.toLowerCase() === nameSanitized.toLowerCase()
    );
    if (duplicateProjects.length > 0) {
      this.setState({
        duplicateProjects: duplicateProjects,
        newProject: project,
        showDuplicateModal: true,
      });
    } else {
      const result = await this.props.createProject(project);
      this.onDone(result?.body ? result?.body._id : null);
    }
  }

  isValidUTF8 = str => {
    try {
      decodeURIComponent(escape(str));
      return false;
    } catch (e) {
      return true;
    }
  };

  onDone(projectId) {
    const { onDone } = this.props;
    this.setState({ showDuplicateModal: false, isSaving: false, newProject: {}, duplicateProjects: [] });
    onDone && onDone(projectId);
  }

  renderDropdownItems() {
    let menuItems = [];
    projectTypes.forEach(projectType => {
      menuItems.push(
        <MenuItem key={projectType} value={projectType}>
          {projectType}
        </MenuItem>
      );
    });
    return menuItems;
  }

  render() {
    const { styles } = this.props;

    return (
      <React.Fragment>
        <form aria-label="form" className={styles.container} onSubmit={e => this.submit(e)} disabled>
          <ThemeProvider theme={theme}>
            <div className={styles.section} data-cy={cypressTags.PROJECT_FORM.PROJECT_NAME}>
              <TextField
                value={this.state.name}
                helperText={this.state.missingNameInput && <span style={{ color: warningRed }}>Name is required</span>}
                id="project-name"
                label={RequiredLabel('Name')}
                placeholder="Name"
                margin="dense"
                className={classnames(styles.input, styles.removeDepth)}
                variant="outlined"
                onChange={event => {
                  this.setState({ name: event.target.value.trimLeft(), missingNameInput: false, isSaving: false });
                }}
              />
            </div>
            <div className={classnames(styles.section)} data-cy={cypressTags.PROJECT_FORM.PROJECT_TYPE}>
              <FormControl variant="outlined" margin="dense" className={classnames(styles.section, styles.input)}>
                <InputLabel className={styles.removeDepth} htmlFor={CLASSES.projectTypeSelector}>
                  {RequiredLabel('Type')}
                </InputLabel>
                <Select
                  value={this.state.type}
                  labelWidth={45}
                  margin="dense"
                  onChange={event => {
                    this.setState({ type: event.target.value, missingTypeInput: false, isSaving: false });
                  }}
                  inputProps={{
                    name: 'type',
                    id: CLASSES.projectTypeSelector,
                  }}
                >
                  {this.renderDropdownItems()}
                </Select>
                {this.state.missingTypeInput && (
                  <FormHelperText style={{ color: warningRed }}>Type is required</FormHelperText>
                )}
              </FormControl>
            </div>
          </ThemeProvider>
          <Tooltip
            title={
              this.isValidUTF8(this.state.name) ? (
                <h4 style={{ fontSize: '18px' }}>
                  Project name cannot contain restricted characters like: é, ä, ô, “ etc. Please remove them to
                  continue.
                </h4>
              ) : (
                ''
              )
            }
          >
            <fieldset
              disabled={this.isValidUTF8(this.state.name)}
              className={styles.confirmButtonsSection}
              style={{ paddingBottom: this.state.missingNameInput || this.state.missingTypeInput ? 20 : 0 }}
              data-cy={cypressTags.PROJECT_FORM.CHECKMARK_BUTTON}
            >
              <DoneButtons isActive={true} isSaving={this.state.isSaving} onDone={() => this.onDone()} />
            </fieldset>
          </Tooltip>
        </form>
        {this.state.showDuplicateModal && (
          <DuplicateModal
            showModal={this.state.showDuplicateModal}
            closeModal={() => {
              this.setState({ showDuplicateModal: false, isSaving: false, newProject: {}, duplicateProjects: [] });
              this.onDone();
            }}
            handleAdd={async () => {
              const result = await this.props.createProject({
                active: true,
                name: this.state.name,
                type: this.state.type,
              });
              this.onDone(result?.body ? result.body._id : null);
              this.setState({ showDuplicateModal: false, isSaving: false, newProject: {}, duplicateProjects: [] });
            }}
            duplicateProjects={this.state.duplicateProjects}
            styles={styles}
          />
        )}
        <div id="duplicate-project-modal" />
      </React.Fragment>
    );
  }
}

const RequiredLabel = text => (
  <span style={{ display: 'flex' }}>
    {text}
    <span style={{ color: warningRed, marginLeft: 5 }}>*</span>
  </span>
);

const DuplicateModal = ({ showModal, closeModal, handleAdd, duplicateProjects, styles }) => {
  function openProject(projectId) {
    window.open(`/project/${projectId}`);
  }

  const ProjectsList = ({ duplicateProjects }) => {
    // sanitize director names
    duplicateProjects.forEach(
      duplicateProject =>
        (duplicateProject.directors = duplicateProject?.executives?.directors
          ? duplicateProject.executives.directors.filter(director => !!director)
          : [])
    );

    return (
      <div className={styles.projectsList}>
        {duplicateProjects.map(project => (
          <div key={project._id} className={styles.projectContainer}>
            <div className={styles.projectName} onClick={() => openProject(project._id)}>
              {project.name}
            </div>
            <div className={styles.projectLabel}>
              <span className={styles.labelContainer}>
                {project.directors.length > 0 && (
                  <span>
                    <b>Director:</b> {project.directors.join(', ')}{' '}
                  </span>
                )}
                {(project.networks || []).length > 0 && (
                  <span>
                    <b>Network:</b> {project.networks.map(network => network.name).join(', ')}{' '}
                  </span>
                )}
                {(project.studios || []).length > 0 && (
                  <span>
                    <b>Studio:</b> {project.studios.map(studio => studio.name).join(', ')}
                  </span>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const containerStyle = {
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    fontFamily: 'aktiv-grotesk',
    border: '1px solid #CCCCCC',
    borderRadius: '1px',
    backgroundColor: '#F5F5F5',
    width: '575px',
    minHeight: '250px',
    padding: '30px 70px',
  };

  return (
    <React.Fragment>
      <Modal
        contentLabel="Add Project Confirmation"
        onRequestClose={() => closeModal()}
        isOpen={showModal}
        style={{
          content: {
            ...containerStyle,
          },
        }}
      >
        <div className={styles.contentContainer}>
          <p className={styles.headerText}>ADD PROJECT CONFIRMATION</p>
          <div className={styles.infoContainer}>
            <div className={styles.promptText}>This name already exists for this project type:</div>
            <ProjectsList duplicateProjects={duplicateProjects} />
            <div className={styles.promptText}>Are you sure you want to add this project?</div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.cancelButton} onClick={() => closeModal()}>
              CANCEL
            </button>
            <button className={styles.addButton} onClick={() => handleAdd()}>
              ADD
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

const theme = createTheme({
  palette: {
    primary: grey,
  },
});

const withStyles = styled({
  container: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'nowrap',
    '-webkit-flex-flow': 'row wrap',
    justifyContent: 'space-around',
  },
  section: {
    width: '45%',
    margin: 8,
  },
  input: {
    width: '100%',
    minWidth: 200,
    display: 'flex',
  },
  confirmButtonsSection: {
    display: 'flex',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    color: '#212529',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'aktiv-grotesk',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  projectContainer: {
    paddingTop: '15px',
  },
  projectsList: {
    paddingBottom: '15px',
  },
  projectName: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#2495D4',
    cursor: 'pointer',
    alignSelf: 'flex-start',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  projectLabel: {
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: '400',
    color: '#28292A',
    width: '100%',
  },
  labelContainer: {
    padding: 0,
    margin: 0,
  },
  promptText: { flex: 1, fontSize: '16px', alignSelf: 'flex-start' },
  buttonContainer: { display: 'flex', justifyContent: 'space-evenly', paddingTop: '30px', width: '100%' },
  cancelButton: {
    color: '#212529',
    backgroundColor: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 'bold',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
  addButton: {
    backgroundColor: '#212529',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    border: '1px solid black',
    marginHorizontal: '30px',
    width: '100px',
    height: '35px',
  },
  removeDepth: {
    zIndex: 0,
  },
});

export default withStyles(ProjectForm);
