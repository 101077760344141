import React, { Component } from 'react';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { notify } from 'react-notify-toast';
import hash from 'object-hash';
import { debounce } from 'lodash';
import { recordEvent, castingSearchCastings, createCastingGrid } from '@united-talent-agency/julius-frontend-store';
import { getGroups, groupRoles } from '../../../../../api/groups';
import { CastingsBadgeBuilder } from '../../../../../components/filter-model/filter-badge-builder';
import CollapsedFilters from '../../../../../components/collapse-filters/collapse-filters';
import ListManager from '../../../../../components/list-manager/list-manager';
import GridManager from '../../../../../components/grid-manager/grid-manager';
import PrintManager from '../../../../../components/print-manager/print-manager';
import CastingFilterForm from '../../../../../components/casting-filter-form/casting-filter-form';
import CastingsSearch from '../../../../../components/search/castings-search';
import Grid from './../algolia/alg-search-casting';
import { downloadFile } from '../../../../../support/download';
import CountLabel from '../../../../../components/count-label/count-label';
import { castingSearchIndexes, castingTypes } from '../../../../../support/items/filter-types';
import { Multiselect } from '../../../../../components/react-multi-select/react-multi-select';

import { transformMongoCastingFilters } from '../../../../../support/algolia/transform-filters';
import { getCastingIndex } from '../../../../../support/algolia/get-index';
import { searchClient } from '../../../../../support/algolia/algolia-search-client';
import { onRemoveBadge } from './utils';
import { getSearchableCastingIndexes } from '../../../../../support/algolia/get-searchable-indexes';

const PAGE_SIZE = 100;
const MAX_RESULTS = 2000;
class SearchCastings extends Component {
  constructor(props) {
    super(props);
    const { grid, filterModel } = this.props;

    const filter = grid ? grid.castingFilter : filterModel;
    this.state = {
      castingsName: filter && (filter.name || filter.castingsName),
      filterModel: props.filterModel,
      areFiltersCollapsed: false,
      isGridManagerCollapsed: true,
      isListManagerCollapsed: true,
      isPrintManagerCollapsed: true,
      isPrinting: false,
      projectCastings: [],
      selectAll: false,
      selection: {},
      lastSearchHash: '',
      sortColumn: 'Project',
      sortDirection: 1,
      gettingData: false,
      castingSearchIndexes,
      projectstoExclude: new Set(),
    };
    this.state.filterModel.limit = PAGE_SIZE;
    this.createGrid = this.createGrid.bind(this);
    this.show = notify.createShowQueue();
    this.filterCastings = this.filterCastings.bind(this);
    this.handleCastingsNameChange = this.handleCastingsNameChange.bind(this);
    this.castingSearchBoxDebounced = debounce(this.filterCastings, 500);
    this.searchCompany = this.searchCompany.bind(this);
    this.getSelectedProjects = this.getSelectedProjects.bind(this);
  }
  handleCastingsNameChange(castingsName) {
    const filterModel = this.state.filterModel;
    filterModel['castingsName'] = castingsName;
    this.setState({
      castingsName,
      filterModel,
    });
    this.castingSearchBoxDebounced(filterModel);
  }

  componentDidMount() {
    const { grid, filterModel } = this.props;
    const filter = grid ? grid.castingFilter : filterModel;
    this.filterCastings(filter);
  }

  hashFilter(filter) {
    const exclusions = function(key) {
      if (key === 'limit' || key === 'skip' || key === 'sort') {
        return true;
      }
      return false;
    };
    return hash(filter, { excludeKeys: exclusions });
  }

  async filterCastings(filter, append = false) {
    const { dispatch } = this.props;
    const { lastSearchHash, gettingData, sortColumn, sortDirection, castings } = this.state;
    if (gettingData) {
      return;
    } else {
      this.setState({ gettingData: true });
    }

    //reset selection if search changes - ignore sorting/pagination
    const searchHash = this.hashFilter(filter);
    if (lastSearchHash !== searchHash) {
      this.setState({
        selectAll: false,
        selection: {},
        isPrintManagerCollapsed: true,
        isListManagerCollapsed: true,
        isGridManagerCollapsed: true,
        lastSearchHash: searchHash,
        projectstoExclude: new Set(),
      });
    }

    if (!filter.castingsName) {
      filter.castingsName = '';
    }

    //first-time search default
    if (!filter.sort) {
      filter.sort = { [sortColumn]: sortDirection };
    }

    if (append) {
      filter.skip = castings.length;
    } else {
      delete filter.skip;
    }

    const index = getCastingIndex(filter,this.props.algoliaKeys);
    let page = filter.skip ? filter.skip / PAGE_SIZE : 0;
    const filters = transformMongoCastingFilters(filter);
    let resultCount = 0;

    let indexSetting = {
      filters,
      hitsPerPage: PAGE_SIZE,
      page,
    };

    let searchableAttributes = [];

    // we only  need to restrict index if one the indexes have been selected
    if (filter.castingSearchIndexes && filter.castingSearchIndexes.length === 1 && filter.castingsName !== undefined) {
      filter.castingSearchIndexes.forEach(index => {
        if (index === 'Casting Role Names') {
          searchableAttributes.push('role');
        }
        if (index === 'Casting Notes') {
          searchableAttributes.push('notes');
        }
        if (index === 'Casting Descriptions') {
          searchableAttributes.push('description');
        }
        if (index === 'Project Names') {
          searchableAttributes.push('projectName');
        }
      });
      indexSetting.restrictSearchableAttributes = searchableAttributes;
    }

    const result = await searchClient(index, filter.castingsName, {
      ...indexSetting,
    });

    resultCount = result.nbHits;

    const existingCastings = append ? Object.assign([], castings) : [];
    const newCastings = append
      ? existingCastings.concat(result.hits ? result.hits : [])
      : result.hits
      ? result.hits
      : [];
    const filterBadges = this.buildCastingBadgesFromFilter(filter);

    dispatch(castingSearchCastings(filter));

    if (filters === '(searchActive:true)' && !filter.castingsName) {
      const getFacetValues = async (client, filters) => {
        const facetValues = await client.searchForFacetValues('searchActive', '', {
          filters,
        });

        return facetValues;
      };
      const facetValues = await getFacetValues(index, filters);
      page = Math.trunc(facetValues.facetHits[0]?.count / 100);

      const resultForActArch = await searchClient(index, filter.castingsName, {
        filters,
        hitsPerPage: PAGE_SIZE,
        page,
      });
      resultCount = resultForActArch.nbHits;
    }

    this.setState({
      filterBadges: filterBadges.badges,
      castings: newCastings,
      gettingData: false,
      resultCount,
      lastSearchHash: this.hashFilter(filter),
    });

    return {
      type: 'CASTING_SEARCH_CASTINGS',
      body: {
        data: result.hits,
      },
    };
  }
  buildCastingBadgesFromFilter(filterModel) {
    const filterBadges = new CastingsBadgeBuilder(filterModel);
    filterBadges.buildCastings();
    filterBadges.badges.forEach(badge => {
      badge.onRemoveBadge = async () =>
        await this.filterCastings(onRemoveBadge(badge, filterModel, this.props.dispatch, this.props.user, recordEvent));
      badge.onPolarityToggle = () => this.onPolarityToggle(badge, filterModel);
    });
    return filterBadges;
  }

  // eslint-disable-next-line no-undef
  onPolarityToggle = (badge, filterModel) => {
    const filterProperty = filterModel[badge.property];
    if (filterProperty.include && badge.exclude === false) {
      const excludeArray = filterProperty.exclude || [];
      const includeIndex = filterProperty.include.findIndex(property => {
        return property.name ? property.name === badge.name : property === badge.name;
      });
      const includeItemToToggle = filterProperty.include.splice(includeIndex, 1)[0];
      excludeArray.push(includeItemToToggle);
      filterProperty.exclude = excludeArray;
      badge.exclude = true;
    } else if (filterProperty.exclude && badge.exclude === true) {
      const includeArray = filterProperty.include || [];
      const excludeIndex = filterProperty.exclude.findIndex(property => {
        return property.name ? property.name === badge.name : property === badge.name;
      });
      const excludeItemToToggle = filterProperty.exclude.splice(excludeIndex, 1)[0];
      includeArray.push(excludeItemToToggle);
      filterProperty.include = includeArray;
      badge.exclude = false;
    }
    this.filterCastings(filterModel);
  };

  render() {
    const props = this.props;
    const state = this.state;
    const { desk, dispatch, styles, user, grid, canEdit } = props;

    const {
      areFiltersCollapsed,
      isPrintManagerCollapsed,
      isListManagerCollapsed,
      isGridManagerCollapsed,
      selectAll,
      selection,
      sortColumn,
      sortDirection,
      resultCount,
      filterModel = {},
      castings,
    } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    if (!desk?.current) {
      return (
        <div className="my-5 text-center">
          <h5>No Desk Assigned</h5>
        </div>
      );
    }
    const columnWidths = {
      tableColumn: areFiltersCollapsed ? styles.tableColumnCollapsed : styles.tableColumn,
      filterColumn: areFiltersCollapsed ? styles.filterColumnCollapsed : styles.filterColumn,
    };

    const selectedTypes = castingTypes.filter(type => {
      return (filter.projectTypes || []).some(fType => fType === type);
    });

    const selectedIndexes = castingSearchIndexes.filter(item => {
      return (filter.castingSearchIndexes || []).some(fType => fType === item);
    });
    const disableSelectBased =
      !castings || castings.length === 0 || (Object.keys(selection).length === 0 && !selectAll);

    return (
      <div>
        <div className={`${styles.content} m-3`}>
          <div className="mx-0 mt-0 mb-0 row">
            <div className={styles.typeSelectionColumn}>
              <div className="pl-0 pr-2 col w-100">
                <Multiselect
                  title="Type"
                  options={castingTypes}
                  selectedOptions={selectedTypes}
                  onSelect={option => {
                    this.onChangeProjectTypes(option);
                  }}
                />
              </div>
            </div>

            <div className={styles.searchInputColumn}>
              <CastingsSearch
                castingsName={state.castingsName}
                onCastingsNameChange={this.handleCastingsNameChange}
                filterModel={filter}
                handleCastingIndexChange={this.handleCastingIndexChange}
                selectedIndexes={selectedIndexes}
              />
              <CountLabel label="result" count={resultCount} className={styles.countLabel} />
            </div>
          </div>
          <div className={styles.container}>
            <div className={columnWidths.filterColumn}>
              {desk && !areFiltersCollapsed && (
                <CastingFilterForm
                  collapseEvent={() => this.collapseEvent('filter')}
                  deskId={desk.current._id}
                  filterModel={filter}
                  dispatch={dispatch}
                  filterCastings={this.filterCastings}
                  updateFilter={this.filterCastings}
                  styles={props.styles}
                  searchCompany={this.searchCompany}
                  algoliaKeys={this.props.algoliaKeys}
                  recordEvent={value => {
                    dispatch(
                      recordEvent(
                        'projects.castings.search.filter-event',
                        'projects-web',
                        'info',
                        '1',
                        [value],
                        user._id
                      )
                    );
                  }}
                />
              )}
              {areFiltersCollapsed && <CollapsedFilters expandEvent={() => this.expandEvent('filter')} />}
            </div>
            <div className={columnWidths.tableColumn}>
              <Grid
                resetFilters={this.clearFilterModel.bind(this)}
                select={true}
                filterModel={filter}
                filterBadges={state.filterBadges}
                castings={castings}
                selectAll={selectAll}
                selection={selection}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                buttons={[
                  {
                    name: 'Print',
                    onClick: () => this.expandEvent('print'),
                    icon: state.isPrinting ? 'circle-o-notch fa-spin' : 'print',
                    hidden: !isPrintManagerCollapsed,
                    disabled: disableSelectBased,
                  },
                  {
                    name: 'Add to List',
                    onClick: () => this.expandEvent('list'),
                    icon: 'plus',
                    hidden: !isListManagerCollapsed,
                    disabled: disableSelectBased,
                  },
                  {
                    name: 'Save as Grid',
                    onClick: () => this.expandEvent('grid'),
                    icon: 'save',
                    hidden: !isGridManagerCollapsed,
                  },
                ]}
                onSelectedChanged={this.onSelectedChanged}
                onSelectAllChanged={this.onSelectAllChanged}
                onSortChanged={(column, direction) => {
                  this.setState({ sortColumn: column, sortDirection: direction });
                  filterModel.sort = { [column]: direction };
                  this.filterCastings(filterModel);
                }}
                maxCount={MAX_RESULTS}
                resultCount={resultCount}
                onFetchMore={() => {
                  this.filterCastings(filter, true);
                }}
              />
            </div>
            {!isListManagerCollapsed && (
              <div className={styles.listColumn}>
                <ListManager
                  desk={desk.current}
                  user={user}
                  dispatch={dispatch}
                  projectsFunc={this.getSelectedProjects}
                  onCancel={() => {
                    this.collapseEvent('list');
                  }}
                  onCreated={list => {
                    this.collapseEvent('list');
                    if (list.corruptProject) {
                      this.show(`New List Created. Projects with errors were not added to list.`, 'custom', 3000, {
                        background: '#FF0000',
                        text: '#FFFFFF',
                      });
                      setTimeout(() => {
                        window.open(`/list/${list._id}`, list._id);
                      }, 3000);
                    } else {
                      this.show(`${list.name} created`, 'custom', 1000, {
                        background: '#000000',
                        text: '#FFFFFF',
                      });
                      window.open(`/list/${list._id}`, list._id);
                    }
                  }}
                  onUpdated={updated => {
                    this.collapseEvent('list');
                    if (updated?.corruptProject) {
                      this.show(`List updated. Projects with errors were not added to list.`, 'custom', 3000, {
                        background: '#000000',
                        text: '#FFFFFF',
                      });
                    } else {
                      this.show(`List updated`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
                    }
                  }}
                  filters={filterModel}
                  type="casting"
                  projectstoExclude={Array.from(this.state.projectstoExclude)}
                  selectAll={selectAll}
                  searchableAttributes={getSearchableCastingIndexes(filterModel)}
                  algoliaKeys={this.props.algoliaKeys}
                />
              </div>
            )}
            {!isPrintManagerCollapsed && (
              <div className={styles.listColumn}>
                <PrintManager
                  grid={grid}
                  canEdit={canEdit}
                  savedOptions={grid && grid.printOptions}
                  isCastingResults={true}
                  updateGrid={this.props.updateGrid}
                  collapseEvent={() => this.collapseEvent('print')}
                  print={this.print}
                  userInTalent={user && user.personId && user.personId.department === 'Talent'}
                  docCount={this.state.resultCount}
                  showSortOptions={this.props.featureFlags.sortOptionForPrinting}
                />
              </div>
            )}
            {!isGridManagerCollapsed && (
              <div className={styles.listColumn}>
                <GridManager
                  collapseEvent={() => this.collapseEvent('grid')}
                  createGrid={this.createGrid}
                  printOptions={filterModel.printOptions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  // eslint-disable-next-line no-undef
  resetFilters = async () => {
    const filterModel = {};
    filterModel.type = this.state.filterModel.type;
    filterModel.active = true;
    filterModel.castingSearchIndexes = castingSearchIndexes;
    this.setState({ filterModel, castingsName: '', projectstoExclude: new Set() });
    await this.filterCastings(filterModel);
  };

  // eslint-disable-next-line no-undef
  onChangeProjectTypes = type => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    const previousTypes = filter.projectTypes || [];
    const newTypes =
      previousTypes.indexOf(type) > -1 ? previousTypes.filter(t => t !== type) : previousTypes.concat(type);
    Object.keys(filter).forEach(key => {
      if (key !== 'castingSearchIndexes') delete filter[key];
    });
    filter.projectTypes = newTypes;
    filter.active = true;
    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterCastings(filterModel);
  };
  handleCastingIndexChange = currentIndex => {
    const { grid } = this.props;
    const { filterModel = {} } = this.state;
    const filter = grid ? grid.castingFilter : filterModel;
    const previousIndexes = filter.castingSearchIndexes || [];

    let newIndexes;

    if (currentIndex === 'All') {
      if (previousIndexes.includes('All')) {
        newIndexes = [];
      } else {
        newIndexes = [...castingSearchIndexes];
      }
    } else {
      newIndexes =
        previousIndexes.indexOf(currentIndex) > -1
          ? previousIndexes.filter(t => t !== currentIndex)
          : previousIndexes.concat(currentIndex);
    }

    filter.castingSearchIndexes = newIndexes;
    filter.active = true;
    if (!grid) {
      this.setState({ filterModel: filter });
    }
    return this.filterCastings(filterModel);
  };
  toCamelCase(str) {
    return str
      .split(' ')
      .map((word, index) =>
        index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join('');
  }

  print = (options = {}) => {
    const { selection, selectAll } = this.state;
    const { grid } = this.props;
    const projects = new Set();
    const castings = new Set();
    Object.keys(selection).forEach(key => {
      castings.add(key);
      projects.add(selection[key]);
    });
    const projectstoExclude = Array.from(this.state.projectstoExclude);
    let fileName = grid ? grid.name : 'Casting Search Results';
    if (options.saveAs) {
      fileName = options.saveAs;
    }
    const indexName = getCastingIndex(this.state.filterModel,this.props.algoliaKeys).indexName;
    if (options.sortBy) {
      options.sortBy = this.toCamelCase(options.sortBy);
    }
    const filter = this.state.filterModel;
    let searchableAttributes = getSearchableCastingIndexes(filter);
    this.performDownload(
      `v2/print-report`,
      fileName,
      {
        options,
        all: selectAll,
        projects: Array.from(projects),
        castings: Array.from(castings),
        algoliaFilters: transformMongoCastingFilters(this.state.filterModel),
        searchTerm: this.state.castingsName,
        indexName,
        restrictSearchableAttributes: searchableAttributes,
        projectstoExclude,
      },
      [{ printType: grid ? 'casting-grid' : 'castingSearchResults' }]
    );
  };

  // eslint-disable-next-line no-undef
  collapseEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: true });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isPrintManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isGridManagerCollapsed: true });
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line no-undef
  expandEvent = event => {
    switch (event) {
      case 'filter':
        this.setState({ areFiltersCollapsed: false });
        break;
      case 'list':
        this.setState({ isListManagerCollapsed: false, isPrintManagerCollapsed: true, isGridManagerCollapsed: true });
        break;
      case 'print':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: false, isGridManagerCollapsed: true });
        break;
      case 'grid':
        this.setState({ isListManagerCollapsed: true, isPrintManagerCollapsed: true, isGridManagerCollapsed: false });
        break;
      default:
        break;
    }
  };

  getSelectedProjects() {
    const { selection, selectAll, castings } = this.state;
    const projects = [
      ...new Set(
        castings
          .filter(casting => {
            return (selectAll && !selection[casting._id]) || (!selectAll && selection[casting._id]);
          })
          .map(casting => {
            return casting.projectId || casting.projectID;
          })
      ),
    ];
    return projects;
  }
  // eslint-disable-next-line no-undef
  createGrid = async (title, printOptions) => {
    const { dispatch, desk } = this.props;
    const { filterModel } = this.state;
    const filterObject = (
      await dispatch(
        createCastingGrid({
          castingFilter: filterModel,
          name: title,
          printOptions: printOptions,
          deskId: desk.current._id,
        })
      )
    ).body;

    this.show(`${filterObject.name} created`, 'custom', 1000, { background: '#000000', text: '#FFFFFF' });
    window.open(`/grid/casting_grid/${filterObject._id}`, filterObject._id);
  };
  // eslint-disable-next-line no-undef
  setIsDownloading = (url, isDownloading) => {
    this.setState({ isPrinting: isDownloading });
  };

  performDownload(url, fileName, options, query) {
    const { user, desk } = this.props;
    if (this.state.isPrinting) {
      return;
    }

    downloadFile(url, fileName, user, desk.current, this.setIsDownloading, options, query);
  }
  // eslint-disable-next-line no-undef
  onSelectAllChanged = isAll => {
    this.setState({ selectAll: isAll, selection: new Set() });
  };

  // eslint-disable-next-line no-undef
  onSelectedChanged = (casting, canExcludeProject) => {
    const { selection, projectstoExclude } = this.state;

    const key = Object.keys(casting)[0];
    const projectId = Object.values(casting)[0];
    if (selection[key]) {
      delete selection[key];
    } else {
      selection[key] = casting[key];
    }
    this.setState({ selection });

    if (this.state.selectAll && !projectstoExclude.has(projectId) && canExcludeProject) {
      projectstoExclude.add(projectId);
      this.setState({ projectstoExclude });
    } else if (this.state.selectAll && projectstoExclude.has(projectId)) {
      projectstoExclude.delete(projectId);
      this.setState({ projectstoExclude });
    } else if (!this.state.selectAll) {
      this.setState({ projectstoExclude: new Set() });
    }
  };

  async searchCompany(value, type) {
    const role = groupRoles[type] || groupRoles.AnyRole;
    const { data } = await getGroups(value, { role });
    return data;
  }

  async clearFilterModel() {
    const badgesToKeep = new Set(['Active Castings', 'Active Projects']);
    const { filterModel } = this.state;

    // Build badges and remove those that don't match the ones we want to keep
    const badges = this.buildCastingBadgesFromFilter(filterModel);
    const removalPromises = badges.badges
      .filter(badge => !badgesToKeep.has(badge.property))
      .map(badge => badge.onRemoveBadge());

    // Wait for all badges to be removed before continuing
    await Promise.all(removalPromises);

    // Define default filters
    const DEFAULT_FILTERS = {
      castingSearchIndexes: ['All', 'Casting Role Names', 'Casting Notes', 'Casting Descriptions', 'Project Names'],
    };

    // Update state and apply the new filter model
    this.setState(
      {
        filterModel: { ...DEFAULT_FILTERS },
        selectAll: false,
        selection: new Set(),
        projectsToExclude: new Set(),
      },
      () => this.filterCastings(this.state.filterModel)
    );
  }
}

const withStyles = styled({
  body: {
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
  },
  tableColumn: {
    flex: 0.815,
  },
  table: {
    display: 'flex',
    width: '80vw',
    height: '90%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerStyles: {
    display: 'flex',
    margin: 'auto',
    width: '100%',
  },
  filterColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
  },
  typeSelectionColumn: {
    display: 'flex',
    flex: 0.185,
    flexDirection: 'column',
    marginBottom: '10px',
  },
  searchInputColumn: {
    display: 'flex',
    flex: 0.815,
    flexDirection: 'column',
    marginBottom: '0px',
  },
  listColumn: {
    minWidth: '300px',
  },
  tableColumnCollapsed: {
    display: 'flex',
    flex: 0.99,
    flexDirection: 'column',
  },
  filterColumnCollapsed: {
    width: 25,
    flexDirection: 'column',
  },
  countLabel: { marginLeft: 8, color: '#666', fontSize: '10pt' },
});

const mapStateToProps = state => {
  return { user: state.user, desk: state.desk };
};

export default connect(mapStateToProps)(withStyles(SearchCastings));
