import moment from 'moment';

class FilterBadgeBuilder {
  constructor(filterModel) {
    this.filterModel = filterModel;
    this.badges = [];
    this._pushBadgeFields = [];
    this._pushCollectionFields = [];
  }

  _pushBadge({ name, custom, label, property, options, exclude = false }) {
    name && this.badges.push({ name, custom, label, options, property, exclude });
  }

  _pushCollection({ collection, label, property, exclude = false }) {
    if (!collection || collection.length === 0) return;
    collection
      .filter(item => !!item)
      .forEach(item => {
        this._pushBadge({
          name: item.name || item,
          custom: item.custom,
          options: item.options,
          label,
          property,
          exclude,
        });
      });
  }

  _constructBadges() {
    this._pushBadgeFields.forEach(pushBadgeField => this._pushBadge(pushBadgeField));
    this._pushCollectionFields.forEach(pushCollectionField => this._pushCollection(pushCollectionField));
  }

  dateFormat(type, dir, date) {
    const dateType = type || 'Start Date';
    return date && `${dateType} ${dir} ${moment(date).format('MM/DD/YYYY')}`;
  }
}

class ProjectsBadgeBuilder extends FilterBadgeBuilder {
  buildProjects() {
    const {
      associatedNames = {},
      networks = {},
      promoters = {},
      productionCompanies = {},
      parentCompanies = {},
      profiles = {},
      studios = {},
      tvStatus = {},
      repAgreementStatus = {},
      salesFinancing = {},
      indieStatus = {},
      locations = {},
      active,
      notActive,
      hasScript,
      genre,
      budget,
      location,
      capacity,
      projectSearchDateType,
      projectDateStart,
      projectDateEnd,
      venueType,
      newInformationOnly,
      locationState,
      classification,
      distribution = {},
    } = this.filterModel;

    this._pushBadgeFields = [
      { name: venueType, label: 'Venue:', property: 'venueType' },
      { name: active, label: 'Active', property: 'active' },
      { name: notActive, label: 'Archived', property: 'notActive' },
      { name: hasScript, label: 'Has Script', property: 'hasScript' },
      { name: budget, label: 'Budget:', property: 'budget' },
      { name: capacity, label: 'Capacity:', property: 'capacity' },
      { name: location, label: 'Location:', property: 'location' },
      { name: locationState, label: 'State:', property: 'locationState' },
      { name: classification, label: 'Classification:', property: 'classification' },
      { name: genre, label: 'Genre:', property: 'genre' },
      { name: newInformationOnly, label: 'New Information Only', property: 'newInformationOnly' },
      {
        name: this.dateFormat(projectSearchDateType, 'From ', projectDateStart),
        label: 'Project From',
        property: 'projectDateStart',
      },
      {
        name: this.dateFormat(projectSearchDateType, 'To ', projectDateEnd),
        label: 'Project To',
        property: 'projectDateEnd',
      },
    ];

    this._pushCollectionFields = [
      { collection: associatedNames.include, label: 'Associated Names', property: 'associatedNames' },
      { collection: associatedNames.exclude, label: 'Associated Names', property: 'associatedNames', exclude: true },
      { collection: networks.include, label: 'Network:', property: 'networks' },
      { collection: networks.exclude, label: 'Network:', property: 'networks', exclude: true },
      { collection: promoters.include, label: 'Promoter:', property: 'promoters' },
      { collection: promoters.exclude, label: 'Promoter:', property: 'promoters', exclude: true },
      { collection: productionCompanies.include, label: 'Production Co:', property: 'productionCompanies' },
      {
        collection: productionCompanies.exclude,
        label: 'Production Co:',
        property: 'productionCompanies',
        exclude: true,
      },
      { collection: parentCompanies.include, label: 'Production Co:', property: 'parentCompanies' },
      { collection: parentCompanies.exclude, label: 'Production Co:', property: 'parentCompanies', exclude: true },
      { collection: profiles.include, label: 'Profile:', property: 'profiles' },
      { collection: profiles.exclude, label: 'Profile:', property: 'profiles', exclude: true },
      { collection: studios.include, label: 'Studio:', property: 'studios' },
      { collection: studios.exclude, label: 'Studio:', property: 'studios', exclude: true },
      { collection: distribution.include, label: 'Distribution:', property: 'distribution' },
      { collection: distribution.exclude, label: 'Distribution:', property: 'distribution', exclude: true },
      { collection: locations.include, label: 'Locations:', property: 'locations' },
      {
        collection: locations.exclude,
        label: 'Locations:',
        property: 'locations',
        exclude: true,
      },
      { collection: tvStatus.include, label: 'TV Status:', property: 'tvStatus' },
      { collection: tvStatus.exclude, label: 'TV Status:', property: 'tvStatus', exclude: true },
      { collection: repAgreementStatus.include, label: 'Rep Agreement:', property: 'repAgreementStatus' },
      {
        collection: repAgreementStatus.exclude,
        label: 'Rep Agreement:',
        property: 'repAgreementStatus',
        exclude: true,
      },
      { collection: salesFinancing.include, label: 'Sales/Finance Co:', property: 'salesFinancing' },
      {
        collection: salesFinancing.exclude,
        label: 'Sales/Finance Co:',
        property: 'salesFinancing',
        exclude: true,
      },
      { collection: indieStatus.include, label: 'Indie Status', property: 'indieStatus' },
      {
        collection: indieStatus.exclude,
        label: 'Indie Status',
        property: 'indieStatus',
        exclude: true,
      },
    ];

    this._constructBadges();
  }
}

class CastingsBadgeBuilder extends FilterBadgeBuilder {
  buildCastings() {
    const {
      newInformationOnly,
      clientRolesOnly = {},
      excludeArchivedRoles,
      includeArchivedCastings,
      includeArchivedProjects,
      ageRange,
      gender = {},
      ethnicities = {},
      statuses = {},
      types = {},
      castingDateType,
      castingDateStart,
      castingDateEnd,
      profiles = {},
      networks = {},
      studios = {},
      productionCompanies = {},
      distribution = {},
      locations = {},
    } = this.filterModel;

    this._pushBadgeFields = [
      { name: includeArchivedCastings, label: 'Archived Castings', property: 'includeArchivedCastings' },
      { name: !includeArchivedCastings, label: 'Active Castings', property: '' },
      { name: includeArchivedProjects, label: 'Archived Projects', property: 'includeArchivedProjects' },
      { name: !includeArchivedProjects, label: 'Active Projects', property: '' },
      { name: excludeArchivedRoles, label: 'Exclude Archived Roles', property: 'excludeArchivedRoles' },
      { name: newInformationOnly, label: 'New Information Only', property: 'newInformationOnly' },
      {
        name: this.dateFormat(castingDateType, 'From', castingDateStart),
        label: 'Casting From ',
        property: 'castingDateStart',
      },
      {
        name: this.dateFormat(castingDateType, 'To', castingDateEnd),
        label: 'Casting To ',
        property: 'castingDateEnd',
      },
    ];

    if (ageRange !== 'Any') this._pushBadgeFields.push({ name: ageRange, label: 'Age Range', property: 'ageRange' });
    this._pushCollectionFields = [
      { collection: clientRolesOnly.include, label: 'Clients Roles Only:', property: 'clientRolesOnly' },
      { collection: clientRolesOnly.exclude, label: 'Clients Roles Only:', property: 'clientRolesOnly', exclude: true },
      { collection: ethnicities.include, label: 'Ethnicities:', property: 'ethnicities' },
      { collection: ethnicities.exclude, label: 'Ethnicities:', property: 'ethnicities', exclude: true },
      { collection: profiles.include, label: 'Profile:', property: 'profiles' },
      { collection: profiles.exclude, label: 'Profile:', property: 'profiles', exclude: true },
      { collection: networks.include, label: 'Network:', property: 'networks' },
      { collection: networks.exclude, label: 'Network:', property: 'networks', exclude: true },
      { collection: studios.include, label: 'Studio:', property: 'studios' },
      { collection: studios.exclude, label: 'Studio:', property: 'studios', exclude: true },
      { collection: productionCompanies.include, label: 'Production Co:', property: 'productionCompanies' },
      {
        collection: productionCompanies.exclude,
        label: 'Production Co:',
        property: 'productionCompanies',
        exclude: true,
      },
      { collection: distribution.include, label: 'Distribution:', property: 'distribution' },
      { collection: distribution.exclude, label: 'Distribution:', property: 'distribution', exclude: true },
      { collection: locations.include, label: 'Locations:', property: 'locations' },
      {
        collection: locations.exclude,
        label: 'Locations:',
        property: 'locations',
        exclude: true,
      },
      { collection: statuses.include, label: 'Status:', property: 'statuses' },
      { collection: statuses.exclude, label: 'Status:', property: 'statuses', exclude: true },
      { collection: gender.include, label: 'Gender:', property: 'gender' },
      { collection: gender.exclude, label: 'Gender:', property: 'gender', exclude: true },
      { collection: types.include, label: 'Type:', property: 'types' },
      { collection: types.exclude, label: 'Type:', property: 'types', exclude: true },
    ];

    this._constructBadges();
  }
}

export { CastingsBadgeBuilder, ProjectsBadgeBuilder };
