import React, { Component } from 'react';
import { NavLink, Route, Switch, Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { helpers, styled } from 'react-free-style';
import { debounce } from 'lodash';
import { Branding } from '@united-talent-agency/components';
import { elements } from '@united-talent-agency/julius-frontend-components';
import { setDesk, loadProjectGrids, loadCastingGrids } from '@united-talent-agency/julius-frontend-store';
import Project from './project-view/index';
import ProjectNotFound from './project-not-found';
import Header from '../shared/header/header';
import ProjectFormMenuComponent from './project-form/menu-component';
import SearchProjectsView from './projects-view/views/search-projects';
import SearchCastingsView from './projects-view/views/search-castings';
import ListsView from './projects-view/views/lists/lists';
import ListView from '../../containers/projects/list-view/index';
import GridsView from '../grids/grids-view/grids';
import { createShortcuts, filterInputEvent, keyboard, stringifyKey } from '../../support/keyboard';
import { loadIntercom } from '../../support/intercom-loader';
import Grid from '../grids/grid-view/grid';
import defaultUtaLogo from '../../assets/logo/UTA_logo_white_200x200.png';
import projectsSymbol from '../../assets/logo/projects_symbol_200x200.png';
import { datadogRum } from '@datadog/browser-rum';
import cypressTags from '../../support/cypressTags';
import { fetchAlgoliaKeys } from '../../api/algolia-api-keys';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const { REACT_APP_SHOW_BANNER } = process.env;

class DeskHeader extends Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, email } = props;
    loadIntercom(firstName, lastName, email);
  }

  render() {
    const { desk, desks, navigate, showReleaseNotes, dispatch } = this.props;
    return (
      <Header
        noDebounce
        desk={desk}
        desks={desks}
        setDesk={desk => {
          return dispatch(setDesk(desk));
        }}
        navigate={navigate}
        showReleaseNotes={showReleaseNotes}
      />
    );
  }
}

class Projects extends Component {
  constructor(props) {
    super(props);
    this.loadGrids = debounce(this.loadGrids.bind(this), 500);
    this.state = {
      isAddOpen: false,
      notes: [],
      notesHash: null,
      isNotesOpen: false,
      deskId: null,
      projectFilters: props.projectFilters,
      castingFilters: props.castingFilters,
      loadedGrids: false,
      algoliaKeys: {},
    };
    this.apiKeyRefreshInterval = null;
  }

  async refreshAlgoliaKeys() {
    try {
      const keys = await fetchAlgoliaKeys();
      if (keys && keys.appId && keys.searchApiKey) {
        this.setState({ algoliaKeys: keys });
      } else {
        console.warn('Received invalid Algolia keys. Keeping previous keys.');
      }
    } catch (error) {
      console.error('Error fetching Algolia keys:', error);
    }
  }

  loadGrids(deskId) {
    const { dispatch } = this.props;
    dispatch(loadProjectGrids(deskId));
    dispatch(loadCastingGrids(deskId));
  }

  componentDidUpdate(prevProps) {
    const { azure_id, firstName, lastName, email, desk } = this.props;

    // Flag allows this to fire 1x per user-login, as desired.
    if (azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: azure_id,
        name: `${lastName}, ${firstName}`,
        email: email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }

    const { loadedGrids } = this.state;
    const deskId = desk && desk._id;
    if ((deskId && prevProps.desk && prevProps.desk._id && deskId !== prevProps.desk._id) || !loadedGrids) {
      this.setState({ loadedGrids: true });
      this.loadGrids(deskId);
    }
  }

  async componentDidMount() {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }

    keyboard.addListener(this.keyboardListener);

    fetchAlgoliaKeys().then(keys => {
      this.setState({ algoliaKeys: keys });
    });

    this.apiKeyRefreshInterval = setInterval(() => {
      this.refreshAlgoliaKeys();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    if (this.apiKeyRefreshInterval) {
      clearInterval(this.apiKeyRefreshInterval);
    }
    keyboard.removeListener(this.keyboardListener);
  }

  render() {
    const props = this.props;
    // this usage of 'user' is broken
    const { user, desk, desks = [], trackings, projectFilters, castingFilters, castingGrids, projectGrids } = props;
    const state = this.state;
    const { styles } = props;
    const { PROJECT_CASTINGS } = cypressTags;

    if (desks.length === 0) {
      return <h5 className="m-4">No current office groups configured. Please contact the Service Desk at x3900</h5>;
    }
    const utaLogo = (
      <Link to="/">
        <img alt="UTA Logo" className={styles.linkedLogo} src={defaultUtaLogo} />
      </Link>
    );
    const topNavigation = (
      <div className={styles.menu}>
        <Row>
          <NavLink exact={true} className={styles.menuItem} activeClassName={styles.activeItem} to="/">
            Search Projects
          </NavLink>
          <NavLink
            data-cy={PROJECT_CASTINGS.PROJECT_CASTINGS_BUTTON}
            className={styles.menuItem}
            activeClassName={styles.activeItem}
            to="/searchCastings"
          >
            Search Castings
          </NavLink>
          <NavLink className={styles.menuItem} activeClassName={styles.activeItem} to="/grids">
            Grids
          </NavLink>
          <NavLink className={styles.menuItem} activeClassName={styles.activeItem} to="/lists">
            Lists
          </NavLink>
        </Row>
        <ProjectFormMenuComponent dispatch={this.props.dispatch} />
      </div>
    );

    return (
      <React.Fragment>
        <Branding text="Projects" utaLogo={utaLogo} symbol={projectsSymbol} showBanner={REACT_APP_SHOW_BANNER} />
        <Switch>
          <Route
            exact
            path="/"
            render={routerProps =>
              projectFilters && (
                <React.Fragment>
                  <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                  {topNavigation}
                  <SearchProjectsView
                    {...routerProps}
                    user={user}
                    desk={desk}
                    trackings={trackings}
                    filterModel={projectFilters}
                    algoliaKeys={state.algoliaKeys}
                  />
                </React.Fragment>
              )
            }
          />
          <Route
            exact
            path="/project-not-found"
            render={routerProps => (
              <React.Fragment>
                <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                <ProjectNotFound {...routerProps} />
              </React.Fragment>
            )}
          />
          <Route
            path="/search"
            render={routerProps =>
              projectFilters && (
                <React.Fragment>
                  <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                  {topNavigation}
                  <SearchProjectsView
                    {...routerProps}
                    user={user}
                    desk={desk}
                    trackings={trackings}
                    filterModel={projectFilters}
                  />
                </React.Fragment>
              )
            }
          />
          <Route
            path="/searchCastings"
            render={routerProps =>
              castingFilters && (
                <React.Fragment>
                  <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                  {topNavigation}
                  <SearchCastingsView
                    {...routerProps}
                    user={user}
                    desk={desk}
                    trackings={trackings}
                    filterModel={castingFilters}
                    algoliaKeys={state.algoliaKeys}
                  />
                </React.Fragment>
              )
            }
          />
          <Route
            path="/grids"
            render={routerProps =>
              castingGrids &&
              projectGrids && (
                <React.Fragment>
                  <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                  {topNavigation}
                  <div className={styles.body}>
                    <GridsView
                      {...routerProps}
                      trackings={trackings}
                      castingGrids={castingGrids}
                      projectGrids={projectGrids}
                      algoliaKeys={state.algoliaKeys}
                    />
                  </div>
                </React.Fragment>
              )
            }
          />
          <Route
            path="/lists"
            render={() => {
              return (
                <React.Fragment>
                  <DeskHeader showReleaseNotes={() => this.setState({ isNotesOpen: !state.isNotesOpen })} {...props} />
                  {topNavigation}
                  <div className={styles.body}>
                    <ListsView {...props} trackings={props.trackings} algoliaKeys={state.algoliaKeys} />
                  </div>
                </React.Fragment>
              );
            }}
          />
        </Switch>
        <Route
          path="/project/:projectId"
          render={renderProps => <Project {...renderProps} desk={desk} user={user} />}
        />
        {props.projectGrids && (
          <Route
            path="/grid/project_grid/:id"
            render={renderProps => (
              <Grid {...renderProps} user={user} desk={desk} projectGrids={projectGrids} trackings={trackings} />
            )}
          />
        )}
        {props.castingGrids && (
          <Route
            path="/grid/casting_grid/:id"
            render={renderProps => (
              <Grid {...renderProps} user={user} desk={desk} castingGrids={castingGrids} trackings={trackings} />
            )}
          />
        )}
        <Route path="/list/:listId" render={renderProps => <ListView {...renderProps} desk={desk} />} />
      </React.Fragment>
    );
  }

  // eslint-disable-next-line no-undef
  keyboardListener = filterInputEvent(
    createShortcuts({
      [stringifyKey('n')]: e => {
        e.preventDefault();
        this.toggleAdd();
      },
    })
  );

  toggleAdd() {
    this.setState({ isAddOpen: !this.state.isAddOpen });
  }
}

const withStyles = styled({
  body: {
    margin: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    display: 'flex',
    margin: '0 18px',
  },
  menuItem: helpers.merge(elements.menuItem, {
    marginTop: 5,
    marginLeft: 10,
    marginRight: 10,
  }),
  activeItem: elements.activeMenuItem,
  linkedLogo: {
    letterSpacing: 4,
    width: 38,
    height: 38,
    cursor: 'pointer',
  },
});

const mapStateToProps = state => {
  const { user = {}, desk, castingGrid, projectGrid } = state;
  return {
    castingFilters: user.castingFilter,
    projectFilters: user.projectFilter,
    desk: desk.current,
    desks: desk.available,
    castingGrids: castingGrid.castingGrids,
    projectGrids: projectGrid.projectGrids,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    azure_id: user.azure_id,
  };
};

export default connect(mapStateToProps)(withStyles(Projects));
