import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { getProjectIndex } from '../../support/algolia/get-index';
import { searchClient } from '../../support/algolia/algolia-search-client';

const defaultAlgoliaFetch = async (searchQuery, pageNumber, selectedOptions, algoliaKeys) => {
  try {
    const filters = `(hasLocation:true)`;
    const index = getProjectIndex({ sort: { Name: 1 } }, algoliaKeys);
    const result = await searchClient(index, searchQuery, {
      filters: filters,
      hitsPerPage: 500,
      page: pageNumber,
    });

    const hits = Array.isArray(result.hits) && result.hits.flatMap(item =>
      item.locations.map(location => ({
        label: location,
        value: { name: location, _id: generateUniqueId(item._id, location) },
      }))
    ) || [];

    const newOptions = [
      ...new Set(
        hits
      ),
    ];

    return { newOptions, hasMore: result.page < result.nbPages - 1 };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const generateUniqueId = (parentId, label) => {
  // Create a simple deterministic unique ID based on _id and label
  const labelHash = label.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0); // simple sum of char codes
  return `${parentId}-${labelHash}`;
};

const LocationFilter = ({
  label = '',
  onSelectLocation,
  algoliaFetchOptions = defaultAlgoliaFetch,
  selectedOptions,
  algoliaKeys,
}) => {
  const [options, setOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = useCallback(
    async (searchQuery, pageNumber, isDefault = false) => {
      setIsLoading(true);
      try {
        const { newOptions, hasMore } = await algoliaFetchOptions(
          searchQuery,
          pageNumber,
          selectedOptions,
          algoliaKeys
        );

        // Remove duplicates based on the 'label' property
        const uniqueOptions = Array.from(new Map(newOptions.map(option => [option.label, option])).values());

        // Filter out options that are already selected
        const filteredOptions = uniqueOptions.filter(
          option => !selectedOptions.some(selected => selected.name === option.label)
        );
        isDefault
          ? setDefaultOptions(filteredOptions)
          : setOptions(prevOptions => (pageNumber === 0 ? filteredOptions : [...prevOptions, ...filteredOptions]));
        setHasMore(hasMore);
      } catch (error) {
        console.error('Algolia search error:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedOptions, algoliaFetchOptions]
  );

  const debouncedFetchOptions = useCallback(debounce(fetchOptions, 100), []);

  useEffect(() => {
    fetchOptions('', 0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue) {
      setPage(0);
      setOptions([]);
      setHasMore(true);
      debouncedFetchOptions(inputValue, 0);
    } else {
      setPage(0);
      setOptions(defaultOptions);
      setHasMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, debouncedFetchOptions]);

  useEffect(() => {
    // Re-fetch options to ensure removed selected options are reflected
    fetchOptions('', 0, true);
  }, [selectedOptions, fetchOptions]);

  const handleInputChange = query => {
    setInputValue(query);
  };

  const handleMenuScrollToBottom = () => {
    if (hasMore && !isLoading) {
      fetchOptions(inputValue, page + 1);
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleFocus = () => {
    if (!inputValue) setOptions(defaultOptions);
  };

  const handleBlur = () => {
    setPage(0);
    setOptions([]);
    setHasMore(true);
  };

  const handleClear = () => {
    setInputValue('');
    setPage(0);
    setOptions([]);
    setHasMore(true);
    setOptions(defaultOptions);
  };

  const NoOptionsMessage = ({ innerProps }) => (
    <div className="px-2 py-4" {...innerProps}>
      No results found
    </div>
  );

  const handleKeyDown = event => {
    if (event.key === ' ' && !event.target.value) {
      event.preventDefault();
    } else if (event.key === 'Backspace' && !event.target.value) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <Select
        options={options.length ? options : defaultOptions}
        onInputChange={handleInputChange}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onFocus={handleFocus}
        placeholder={label}
        isLoading={isLoading}
        styles={customStyles}
        onChange={selectedItems => {
          if (selectedItems && selectedItems.length > 0) {
            onSelectLocation(selectedItems[selectedItems.length - 1]?.value);
          } else {
            onSelectLocation([]);
          }
        }}
        isMulti={true}
        controlShouldRenderValue={false}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage,
        }}
        isClearable={false}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClear={handleClear}
        hideSelectedOptions={true}
      />
    </div>
  );
};

export default React.memo(LocationFilter);

const debounce = (func, delay) => {
  let debounceTimer;
  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const customStyles = {
  control: base => ({
    ...base,
    borderRadius: 0,
    border: '1px solid #EBEBEB',
    fontSize: '13px',
    boxShadow: 'none',
    cursor: 'text',
    '&:hover': {
      border: 'none',
    },
  }),
  menu: base => ({
    ...base,
    backgroundColor: '#FFF',
    border: '1px solid #EBEBEB',
    boxShadow: 'none',
    marginTop: 0,
    marginBottom: 0,
    borderRadius: 0,
  }),
  menuList: base => ({
    ...base,
    fontSize: '13px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#f5f5f5' : '#FFF',
    color: state.isFocused ? '#000' : '#333',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  }),
};
