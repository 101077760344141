import React, { Component } from 'react';
import { styled } from 'react-free-style';
import classnames from 'classnames';

import { personProfileLink } from '../../support/person';

import { colors } from '@united-talent-agency/julius-frontend-components';

const FIELD_MAP = {
  literaryAgents: 'Literary Agents',
  talentAgents: 'Talent Agents',
  studios: 'Studios',
  networks: 'Networks',
  promoters: 'Promoters',
  productionCompanies: 'Production Companies',
  castings: 'Castings',
  trackings: 'Trackings',
  roles: 'Positions',
  newInfo: 'New Information',
  indieAgents: 'Indie Agents',
  projectProfiles: 'Project Profiles',
};

class History extends Component {
  render() {
    const { styles, history } = this.props;
    const { timestamp, delta, recordDeleted, user } = history;

    if (!delta) return null;

    const isSteward = false;

    return Object.entries(delta).map((change, index) => {
      let oldValue = change[1][0];
      let newValue = change[1][1];
      if (newValue === undefined) {
        newValue = oldValue;
        oldValue = '';
      }

      return (
        <tr className={classnames(styles.td, 'historyRow')} key={index}>
          <td className={styles.column}>
            <p className={`${styles.data} ${styles.zeroMarginPadding}`}>
              {this.parseDate(timestamp) ? this.parseDate(timestamp) : 'undefined'}
            </p>
          </td>
          <td className={styles.column}>
            <p className={`${styles.data} ${styles.zeroMarginPadding}`}>{this.parseField(change[0])}</p>
          </td>
          <td className={styles.column}>
            {Array.isArray(oldValue) ? (
              oldValue?.map((val, i) => (
                <p key={i} className={`${styles.description} ${styles.zeroMarginPadding}`}>
                  {this.renderValue(this.parseField(change[0]), val)}
                </p>
              ))
            ) : (
              <p className={`${styles.description} ${styles.zeroMarginPadding}`}>
                {this.renderValue(this.parseField(change[0]), oldValue, true)}
              </p>
            )}
          </td>
          <td className={styles.column}>
            {Array.isArray(newValue) ? (
              newValue.map((val, i) => (
                <p key={i} className={`${styles.description} ${styles.zeroMarginPadding}`}>
                  {this.renderValue(this.parseField(change[0]), val)}
                </p>
              ))
            ) : (
              <p className={`${styles.description} ${styles.zeroMarginPadding}`}>
                {this.renderValue(this.parseField(change[0]), newValue, true)}
              </p>
            )}
          </td>
          <td>
            {recordDeleted ? 'Deleted' : change[0] === 'project' && oldValue.length === 0 ? 'Created' : 'Updated'}
          </td>
          <td>
            <p className={`${styles.person} ${styles.zeroMarginPadding}`}>
              {user._id !== undefined ? (
                <a
                  className={styles.profileLink}
                  href={personProfileLink(user._id, isSteward)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user.name}
                </a>
              ) : (
                user.name || ''
              )}
            </p>
          </td>
        </tr>
      );
    });
  }

  parseField(field) {
    if (!field) return;
    return FIELD_MAP[field] ? FIELD_MAP[field] : field.charAt(0).toUpperCase() + field.slice(1).toLowerCase();
  }

  parseDate(timestamp) {
    if (!timestamp) return;
    const date = new Date(timestamp);
    // Check if the parsed date is a valid date
    if (!isNaN(date.getTime())) {
      // If it's a valid date, return the date in ISO format
      return date.toISOString().slice(0, 10);
    } else {
      // If it's not a valid date, return undefined
      return undefined;
    }
  }

  // eslint-disable-next-line no-undef
  zeroPad = (number, places) => {
    const zero = places - number.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + number;
  };

  // Function to check if a value is a valid date
  isValidDate = value => {
    return value instanceof Date && !isNaN(value);
  };

  renderValue(fieldName, value, isSingle = false) {
    const isInternal = fieldName == 'Literary notes: internal';
    if (typeof value === 'object' && value !== null) {
      if (value['company'] !== undefined) return String(value['company'].name);
      if (value['name'] !== undefined) return String(value['name']);
      if (value['reader']?.name !== undefined) return String(value['reader'].name);
      return value._id || '';
    }
    if (typeof value === 'boolean') {
      return isInternal ? (value ? 'Yes' : 'No') : String(value);
    }
    if (typeof value === 'string') {
      return String(value);
    }
    if (typeof value === 'number' && value !== undefined && isSingle) {
      return this.parseDate(value);
    }
    return value || '';
  }
}

const withStyles = styled({
  td: {
    '> td': {
      padding: 10,
      fontSize: 11,
      fontWeight: 300,
      color: colors.text,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
    '&:hover': {
      border: `1px solid ${colors.focusBorder}`,
      backgroundColor: colors.focusBackground,
    },
  },
  person: {
    width: '150px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
  zeroMarginPadding: {
    margin: 0,
    padding: 0,
  },
  data: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  description: {
    display: 'flex',
    width: 275,
    textOverflow: 'ellipsis',
  },
  italics: {
    fontStyle: 'italic',
  },
  profileLink: {
    cursor: 'pointer',
    color: '#000',
  },
});

export default withStyles(History);
