import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import editUrl from '../../assets/images/pencil.svg';

const EditButton = props => {
  const { onClick, testId, cypressId } = props;
  const classes = useStyles();

  return (
    <div
      data-testid={testId}
      data-cy={cypressId}
      onClick={onClick}
      className={classnames(classes.container, classes.icon)}
    >
      <i className={classnames(classes.icon, classes.editIcon)} />
      <span>Edit</span>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: 1,
    padding: '5px 0',
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: 5,
    marginLeft: 10,
  },
  editIcon: {
    width: 10,
    height: 10,
    backgroundImage: `url(${editUrl})`,
    display: 'inline-block',
    backgroundSize: 'cover',
  },
});

export { EditButton };
