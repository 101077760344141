import algoliasearch from 'algoliasearch';

export const algoliaClient = (appId, apiKey) => {

  return algoliasearch(appId || ' ', apiKey || ' ');
};

export const searchClient = async (searchIndex, searchQuery, searchParams) => {
  if (!searchIndex) return {hits:0};
  
  const result = await searchIndex.search(searchQuery, searchParams);
  return result;
};

export const searchObject = async (index, id) => {
  const result = await index.getObject(id);
  return result;
};
