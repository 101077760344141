import { get } from './index.js';

export const fetchAlgoliaKeys = async () => {
  try {
    const response = await get(`/v2/algolia-api-keys`);
    if (!response.ok) throw new Error('Failed to fetch Algolia API keys');

    const { algoliaKeys } = response.body;

    if (!algoliaKeys.appId || !algoliaKeys.searchApiKey) throw new Error('Missing Algolia API keys');

    return algoliaKeys;
  } catch (error) {
    console.error('Error fetching Algolia keys:', error);
    return null;
  }
};
