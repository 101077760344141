import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import Calendar from '../../components/calendar/calendar';
import { defaultBudgetValues, theatreBudgetValues } from '../../support/items/budgets';
import { tvStatuses } from '../../support/items/tv-statuses';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Info } from '../info/info';
import { hasAccess } from '../../support/permissions';
import indieStatuses from '../../support/items/indie-statuses';
import MultiSelectCities from '../locations/cities';

const EditProjectInfoSansStyling = ({ updateProject, project, testId }) => {
  let budgetValues = defaultBudgetValues;
  if (project.type === 'Theatre') {
    budgetValues = theatreBudgetValues;
  }
  const budgetOptions = budgetValues.map(budget => {
    return { value: budget, label: budget, onClick: () => updateProject({ budget }) };
  });

  const tvStatusOptions = tvStatuses.map(tvStatus => {
    return { value: tvStatus, label: tvStatus };
  });

  const [start, setStart] = useState(
    !!project['opened_date'] && typeof project['opened_date'] === 'string'
      ? moment(project['opened_date']).format('YYYY-MM-DD')
      : ''
  );
  const [thru, setThru] = useState(
    !!project['thru_date'] && typeof project['thru_date'] === 'string'
      ? moment(project['thru_date']).format('YYYY-MM-DD')
      : ''
  );

  const [dateNotes, setDateNotes] = useState(project.date_notes || '');
  const [budget, setBudget] = useState(project.budget || '');
  const [budgetNotes, setBudgetNotes] = useState(project.budget_notes || '');
  const [location_notes, setLocationNotes] = useState(project.location_notes || '');
  const [locationCity, setLocationCity] = useState(project.locations || []);
  const [blurb, setBlurb] = useState(project.blurb || '');
  const [tvStatus, setTvStatus] = useState(project.tvStatus || '');
  const [indieStatus, setIndieStatus] = useState(project.indieStatus || '');
  const [script, setScript] = useState(project.script || '');
  const [error, setError] = useState(false);

  const handleSelectionChange = selectedCities => {
    setLocationCity(selectedCities);
    updateProject({ locations: selectedCities });
  };

  const handleBudgetChange = event => {
    setBudget(event.target.value);
    updateProject({ budget: event.target.value });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    event.persist();
    switch (name) {
      case 'Date Notes':
        setDateNotes(value);
        updateProject({ date_notes: value });
        break;
      case 'Budget Notes':
        setBudgetNotes(value);
        updateProject({ budget_notes: value });
        break;
      case 'Location Notes':
        setLocationNotes(value);
        updateProject({ location_notes: value });
        break;
      case 'Blurb':
        setBlurb(value);
        updateProject({ blurb: value });
        break;
      case 'Status':
        setTvStatus(value);
        updateProject({ tvStatus: value });
        break;
      case 'Script':
        setScript(value);
        setError(!isValidUrl(value));
        updateProject({ script: value });
        break;
      default:
        break;
    }
  };
  return (
    <form data-testid={testId}>
      <Info type="text" name="Last Updated">
        {project.last_updated_date && moment(project.last_updated_date).format('YYYY-MM-DD')}
      </Info>
      <Info type="text" name="Type">
        {project.type}
      </Info>
      {(project.type === 'Unscripted' || project.type === 'Feature') && hasAccess('details', 'indieStatus') && (
        <TextField
          select
          variant="outlined"
          fullWidth
          label="Indie Status"
          value={indieStatus}
          onChange={e => {
            setIndieStatus(e.target.value);
            updateProject({ indieStatus: e.target.value });
          }}
          margin="dense"
        >
          {[''].concat(indieStatuses).map(option => (
            <MenuItem key={option} value={option}>
              {option === '' ? String.fromCharCode(160) : option}
            </MenuItem>
          ))}
        </TextField>
      )}
      <Calendar
        key="opened_date"
        title="Starts"
        value={start}
        onChange={date => {
          setStart(date);
          updateProject({ opened_date: date && date.valueOf() });
        }}
      />
      <Calendar
        key="thru_date"
        title="Thru"
        value={thru}
        onChange={date => {
          setThru(date);
          updateProject({ thru_date: date && date.valueOf() });
        }}
      />
      {project.type === 'Television' && (
        <TextField
          select
          variant="outlined"
          fullWidth
          label="Status"
          name="Status"
          placeholder="Status"
          value={tvStatus}
          onChange={handleChange}
          margin="dense"
        >
          {tvStatusOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        fullWidth
        key="date_notes"
        margin="dense"
        variant="outlined"
        label="Date Notes"
        name="Date Notes"
        placeholder="Date Notes"
        value={dateNotes}
        onChange={handleChange}
      />
      {project.type === 'Theatre' ? null : (
        <TextField
          select
          variant="outlined"
          fullWidth
          label="Budget"
          value={budget}
          onChange={handleBudgetChange}
          margin="dense"
        >
          {budgetOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      {project.type === 'Theatre' ? null : (
        <TextField
          fullWidth
          label="Budget Notes"
          margin="dense"
          variant="outlined"
          name="Budget Notes"
          placeholder="Budget Notes"
          value={budgetNotes}
          onChange={handleChange}
        />
      )}

      <div className="pt-2 pb-1">
        <MultiSelectCities
          defaultValues={locationCity} // Pass default city names
          onSelectionChange={handleSelectionChange} // Receive city names
        />
      </div>

      <TextField
        fullWidth
        label="Location Notes"
        margin="dense"
        variant="outlined"
        name="Location Notes"
        placeholder="Location Notes"
        value={location_notes}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        label="Script"
        margin="dense"
        variant="outlined"
        name="Script"
        placeholder="Script"
        value={script}
        onChange={handleChange}
        helperText={error ? 'Please enter a valid URL' : ''}
      />
      <TextField
        multiline
        rows={4}
        fullWidth
        label="Blurb"
        margin="dense"
        variant="outlined"
        name="Blurb"
        placeholder="Blurb"
        value={blurb}
        onChange={handleChange}
      />
    </form>
  );
};

const styles = {
  formControl: {
    minWidth: 120,
  },
};
const EditProjectInfo = withStyles(styles)(EditProjectInfoSansStyling);
export default EditProjectInfo;

export const isValidUrl = value => {
  const pattern = new RegExp(
    "^https:\\/\\/unitedtalent\\.app\\.box\\.com\\/(?:[-a-zA-Z\\d%_.~+#!$&'()*+,;=:@/]*)$",
    'i'
  );
  return pattern.test(value);
};
